import * as scryfall from 'utils/scryfall'

import { nameForCard } from './nameForCard'

export function formatText(cards: scryfall.Card[]): string {
  return cards.map(nameForCard).join('\n')
}

export function formatTextWithSet(cards: scryfall.Card[]): string {
  return cards.map((card) => `${nameForCard(card)} [${card.set}]`).join('\n')
}
