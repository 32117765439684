import * as styles from './SetCubeBuilder.module.scss'

import React, { useCallback, useEffect, useState } from 'react'

import * as scryfall from 'utils/scryfall'

import * as controls from 'components/controls'

import { useListConfiguration } from './data/listConfiguration'
import { fetchSetWithCards } from './data/fetchCards'

import { AllSetsModal } from './AllSetsModal'
import { Configuration } from './Configuration'
import { CubeList } from './CubeList'

interface Props {
  initialSetCode?: string | null
}

export const SetCubeBuilder: React.FC<Props> = (props) => {
  const { initialSetCode } = props

  const [query, setQuery] = useState('')

  const [fetchResult, setFetchResult] = useState<Awaited<
    ReturnType<typeof fetchSetWithCards>
  > | null>(null)

  const [showAllSets, setShowAllSets] = useState(false)
  const [loading, setLoading] = useState(false)

  const sets = scryfall.useSets()

  const update = useCallback(
    (query: string) => {
      setLoading(true)
      setFetchResult(null)
      fetchSetWithCards(sets, query).then((result) => {
        setLoading(false)
        setFetchResult(result)
      })
    },
    [sets],
  )

  const fetchSet = useCallback(() => {
    update(query)
  }, [update, query])

  const selectSet = useCallback(
    (set: scryfall.Set) => {
      setShowAllSets(false)
      setQuery(set.name)
      update(set.code)
    },
    [update],
  )

  useEffect(() => {
    if (initialSetCode && sets != null) {
      setQuery(initialSetCode)
      update(initialSetCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sets])

  const onKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        fetchSet()
      }
    },
    [fetchSet],
  )

  const set = fetchResult?.error == null ? fetchResult : null

  const listConfiguration = useListConfiguration(set)

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div>Set Name or Code</div>
        <div className={styles.controls}>
          <div className={styles.inputField}>
            <controls.TextInput
              type="text"
              value={query}
              onChange={(event) => setQuery(event.currentTarget.value)}
              onKeyPress={onKeyPress}
            />
            <controls.SmallButton onClick={fetchSet}>
              Load Set
            </controls.SmallButton>
          </div>

          <controls.TextButton onClick={() => setShowAllSets(true)}>
            View All Sets
          </controls.TextButton>
        </div>
      </div>

      {fetchResult?.error != null && (
        <div className={styles.error}>{fetchResult.error}</div>
      )}

      <Configuration loading={loading} listConfiguration={listConfiguration} />

      {!loading && fetchResult?.error == null && (
        <CubeList listConfiguration={listConfiguration} />
      )}

      <AllSetsModal
        sets={sets ?? []}
        selectSet={selectSet}
        presented={showAllSets}
        dismiss={() => setShowAllSets(false)}
      />
    </div>
  )
}
