import * as styles from './AllSetsModal.module.scss'

import React, { useMemo } from 'react'

import * as scryfall from 'utils/scryfall'

import * as controls from 'components/controls'

import { filterMainSets } from './data/filterMainSets'

interface Props {
  sets: scryfall.Set[]
  selectSet(set: scryfall.Set): void
  presented: boolean
  dismiss(): void
}

export const AllSetsModal: React.FC<Props> = (props) => {
  const { sets, selectSet, ...modalProps } = props

  const mainSets = useMemo(() => {
    return filterMainSets(sets)
  }, [sets])

  return (
    <controls.Modal {...modalProps}>
      <div className={styles.content}>
        <div className={styles.heading}>All Sets</div>

        <div className={styles.links}>
          {mainSets.map((set) => (
            <div key={set.code}>
              <button
                type="button"
                onClick={() => selectSet(set)}
                className={styles.setButton}
              >
                <img src={set.icon_svg_uri} alt="" className={styles.icon} />{' '}
                {set.name}
              </button>
            </div>
          ))}
        </div>
      </div>
    </controls.Modal>
  )
}
