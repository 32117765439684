import * as scryfall from 'utils/scryfall'

/**
 * Parse the correct name for Cube Cobra import from a card based on the layout
 * type.
 */
export function nameForCard(card: scryfall.Card) {
  if (card.layout === 'adventure' || card.layout === 'flip') {
    return card.card_faces![0].name
  }
  return card.name
}
