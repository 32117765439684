// title: MTG Set Cube Builder — Lucky Paper
// frameCardName: Tablet of Compleation
// frameCardSet: one
// lastUpdatedDate: 2023-02-05

import * as styles from './index.module.scss'

import React from 'react'
import { Link, PageProps } from 'gatsby'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'
import * as Typography from 'components/typography'

import { SetCubeBuilder } from 'components/set-cube-builder/SetCubeBuilder'

interface Props extends PageProps {
  pageContext: any
}

const SetCubeBuilderPage: React.FC<Props> = (props) => {
  const setCode = (props.location.state as any)?.setCode

  return (
    <Layout {...props.pageContext} accentColor="#49AEA9">
      <div className={styles.container}>
        <div className={styles.heading}>
          <Typography.PrimaryHeading>
            MTG Set Cube Builder
          </Typography.PrimaryHeading>
        </div>

        <div className={styles.intro}>
          <Typography.Paragraph>
            Build a list for a{' '}
            <Link to="articles/set-cubes-and-more/">Set Cube</Link>. Choose a
            set and the number of cards at each rarity and download a list ready
            to import to <a href="https://cubecobra.com/">Cube Cobra</a> or your
            favorite Cube management tool.
          </Typography.Paragraph>
        </div>

        <SetCubeBuilder initialSetCode={setCode} />

        <Typography.Divider />

        <Typography.Paragraph>
          Lucky Paper creates <Link to="/resources/">tools</Link>,{' '}
          <Link to="/articles/">articles</Link>, and a{' '}
          <Link to="/podcast/">podcast</Link> about Magic: the Gathering with a
          special focus on <Link to="/what-is-a-cube/">Cube</Link> and similar
          sandbox formats.
        </Typography.Paragraph>
      </div>
    </Layout>
  )
}

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'MTG Set Cube Builder — Lucky Paper',
        description:
          'Build a list for a Magic: the Gathering set Cube. Choose a set and the number of cards at each rarity and download a list ready to import to Cube Cobra or your favorite Cube management tool.',
        author: 'Anthony Mattox',
        ogType: 'website',
        ogImageURL: 'https://luckypaper.co/og-images/set-cube-builder.jpg',
        ogImageWidth: '2400',
        ogImageHeight: '1260',
      }}
      schema={{
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name: 'Magic: the Gathering Set Cube Builder — Lucky Paper',
        url: props.pageContext.url,
      }}
    />
  )
}

export default SetCubeBuilderPage
