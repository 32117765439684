import * as styles from './CubeList.module.scss'

import React from 'react'

import * as controls from 'components/controls'

import CardHover from 'components/cards/CardHover'

import { ListConfiguration } from './data/listConfiguration'
import { nameForCard } from './data/nameForCard'
import {
  cubeCobraHeadings,
  formatCardCSVRow,
  formatCSVRow,
} from './data/formatCSV'

const exportOptions = [
  { name: 'name', label: 'Card Names' },
  { name: 'name-set', label: 'Card Names [set]' },
  { name: 'csv', label: 'CSV' },
]

interface Props {
  listConfiguration: ListConfiguration
}

export const CubeList: React.FC<Props> = (props) => {
  const { set, list, exportFormat, setExportFormat, download, copy } =
    props.listConfiguration

  if (!set) {
    return null
  }

  return (
    <div>
      <div className={styles.heading}>
        <div>Cube List - {list.length} Total Cards</div>
        <div className={styles.copyButtons}>
          <controls.Select
            options={exportOptions}
            value={exportFormat}
            onChange={(event) =>
              setExportFormat(event.currentTarget.value as any)
            }
          />
          <controls.SmallButton onClick={download}>
            Download
          </controls.SmallButton>
          <controls.SmallButton onClick={copy}>
            Copy to Clipboard
          </controls.SmallButton>
        </div>
      </div>

      <div>
        <div className={styles.listPreview}>
          {list.length === 0 && (
            <div className={styles.empty}>No Cards Found</div>
          )}

          {exportFormat === 'csv' && (
            <div>{formatCSVRow(cubeCobraHeadings)}</div>
          )}
          {list.slice(0, 20).map((card, index) => (
            <div key={index}>
              <CardHover card={card.name} set={card.set}>
                {exportFormat === 'name' ? (
                  <>{nameForCard(card)}</>
                ) : exportFormat === 'name-set' ? (
                  <>
                    {nameForCard(card)} [{card.set}]
                  </>
                ) : (
                  <>{formatCardCSVRow(card)}</>
                )}
              </CardHover>
            </div>
          ))}
          {list.length > 20 && (
            <div className={styles.andMore}>
              ... ({list.length} total cards)
            </div>
          )}
        </div>

        <p className={styles.note}>
          Use a CSV to maintain the correct set. CSVs are formatted for import
          to <a href="https://cubecobra.com">Cube Cobra</a>. Data courtesy of{' '}
          <a href="https://scryfall.com">Scryfall</a>
        </p>
      </div>
    </div>
  )
}
