import * as scryfall from 'utils/scryfall'

import { rarities } from './rarities'

export function buildList(
  cards: Record<scryfall.Rarity, scryfall.Card[]>,
  counts: Record<scryfall.Rarity, number>,
): scryfall.Card[] {
  return rarities.flatMap((rarity) => {
    const count = counts[rarity]
    return cards[rarity].flatMap((card) => Array(count).fill(card))
  })
}
