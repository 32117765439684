import * as scryfall from 'utils/scryfall'

import { nameForCard } from './nameForCard'

export const cubeCobraHeadings = [
  'Name',
  'CMC',
  'Type',
  'Color',
  'Set',
  'Collector Number',
  'Rarity',
  'Color Category',
  'Status',
  'Finish',
  'Maybeboard',
  'Image URL',
  'Image Back URL',
  'Tags',
  'Notes',
  'MTGO ID',
]

export function formatCSVRow(values: string[]): string {
  return values
    .map((value) =>
      value.length > 0 && value.includes(',') ? `"${value}"` : value,
    )
    .join(',')
}

export function formatCardCSVRow(card: scryfall.Card) {
  const row = [
    nameForCard(card),
    card.cmc.toString(),
    '',
    '',
    card.set,
    card.collector_number,
    card.rarity,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ]
  return `${formatCSVRow(row)}`
}

export function formatCSV(cards: scryfall.Card[]) {
  const rows = cards.map(formatCardCSVRow)

  return [formatCSVRow(cubeCobraHeadings), ...rows].join('\n')
}
