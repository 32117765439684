import * as styles from './Configuration.module.scss'

import React from 'react'
import classNames from 'classnames'

import Indicator from 'components/loading/Indicator'

import { ListConfiguration } from './data/listConfiguration'
import { rarities } from './data/rarities'

import { RarityConfiguration } from './RarityConfiguration'

interface Props {
  loading: boolean
  listConfiguration: ListConfiguration
}

export const Configuration: React.FC<Props> = (props) => {
  const { loading } = props
  const { set, stats } = props.listConfiguration

  return (
    <div>
      <div className={styles.setSummary}>
        {loading ? (
          <Indicator />
        ) : set != null ? (
          <div className={styles.setName}>
            <img src={set.icon_svg_uri} alt="" className={styles.setSymbol} />{' '}
            <div>
              {set.name} - {set.cards.length} Cards
            </div>
          </div>
        ) : (
          <div className={styles.empty}>Choose a set to build a cube list</div>
        )}
      </div>

      <div className={styles.controls}>
        {rarities.map((rarity) => (
          <RarityConfiguration
            key={rarity}
            rarity={rarity}
            listConfiguration={props.listConfiguration}
          />
        ))}

        <div
          className={classNames(
            styles.percentResultsRarity,
            styles.commonUncommonPercent,
          )}
        >
          {stats.commonPercent != null && (
            <>{Math.round(stats.commonPercent)}% </>
          )}
          <span className={styles.standardPercent}> (71.5%)</span>
        </div>

        <div
          className={classNames(
            styles.percentResultsRarity,
            styles.commonUncommonPercent,
          )}
        >
          {stats.uncommonPercent != null && (
            <>{Math.round(stats.uncommonPercent)}% </>
          )}
          <span className={styles.standardPercent}> (21.5%)</span>
        </div>

        <div
          className={classNames(
            styles.percentResultsRarity,
            styles.rareMythicPercent,
          )}
        >
          <div className={styles.span} />
          <div className={styles.percentValue}>
            {stats.rareAndMythicPercent != null && (
              <>{Math.round(stats.rareAndMythicPercent)}% </>
            )}
            <span className={styles.standardPercent}> (7%)</span>
          </div>
        </div>
      </div>

      <div className={styles.percentLegend}>
        Cube% <span className={styles.standardPercent}>(Booster%)</span>{' '}
      </div>

      <div className={styles.notes}>
        <p>
          Choose the number of copies of cards at each rarity. Set Cubes often
          use a rarity of 4-2-1-1. Use the list as is to replicate a draft, or
          as a starting point to customize.
        </p>
      </div>
    </div>
  )
}
