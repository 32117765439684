import { groupBy, kebabCase } from 'lodash'

import * as scryfall from 'utils/scryfall'

export type SetWithCards = scryfall.Set & {
  cards: scryfall.Card[]
  cardsByRarity: Record<scryfall.Rarity, scryfall.Card[]>
}

export async function fetchSetWithCards(
  sets: scryfall.Set[] | null,
  query: string,
) {
  const slug = kebabCase(query.trim())
  const set = sets?.find((set) => {
    return kebabCase(set.code) === slug || kebabCase(set.name) === slug
  })

  if (set == null) {
    return {
      error: 'Set not found',
    }
  }

  let cards = await scryfall.search(
    `e:${set.code} is:booster -is:boosterfun -t:basic`,
    { maxPages: 20 },
  )

  // if the set includes no cards, try the search again without `is:booster`.
  // This allows fetching set extensions like "STA" and commander decks.
  if (cards.length === 0) {
    cards = await scryfall.search(`e:${set.code} -is:boosterfun -t:basic`, {
      maxPages: 20,
    })
  }

  // It doesn't seem to be possible to filter out meld cards in the search
  // above.
  const filteredCards = cards.filter(
    (card) =>
      !(
        card.layout === 'meld' &&
        card.all_parts?.find((part) => part.id === card.id)?.component ===
          'meld_result'
      ),
  )

  const cardsByRarity = {
    common: [],
    uncommon: [],
    rare: [],
    mythic: [],
    ...groupBy(filteredCards, (card) => card.rarity),
  } as Record<scryfall.Rarity, scryfall.Card[]>

  return {
    ...set,
    cards,
    cardsByRarity,
  }
}
