import * as styles from './RarityConfiguration.module.scss'

import React from 'react'
import classNames from 'classnames'
import { upperFirst } from 'lodash'

import { Rarity } from 'utils/scryfall'

import * as controls from 'components/controls'

import { ListConfiguration } from './data/listConfiguration'

interface Props {
  rarity: Rarity
  listConfiguration: ListConfiguration
}

export const RarityConfiguration: React.FC<Props> = (props) => {
  const { rarity } = props

  const { set, counts, setCountForRarity } = props.listConfiguration

  const cardCountInSet = set != null ? set.cardsByRarity[rarity].length : 0

  const disabled = cardCountInSet === 0

  return (
    <div
      className={classNames(styles.container, {
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.rarityName}>{upperFirst(rarity)}</div>

      <div className={styles.rarityControls}>
        <controls.TextInput
          type="text"
          value={disabled ? 0 : counts[rarity]}
          onChange={(event) =>
            setCountForRarity(rarity, event.currentTarget.value)
          }
          disabled={disabled}
        />
        × {cardCountInSet} in set
      </div>
    </div>
  )
}
