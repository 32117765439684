// extracted by mini-css-extract-plugin
export var commonUncommonPercent = "Configuration-module--commonUncommonPercent--87298";
export var controls = "Configuration-module--controls--9435b";
export var empty = "Configuration-module--empty--6aa16";
export var loadingIndicator = "Configuration-module--loadingIndicator--275cd";
export var notes = "Configuration-module--notes--b3749";
export var percentLegend = "Configuration-module--percentLegend--57389";
export var percentResultsRarity = "Configuration-module--percentResultsRarity--aa301";
export var percentValue = "Configuration-module--percentValue--384b9";
export var rareMythicPercent = "Configuration-module--rareMythicPercent--17a2a";
export var setName = "Configuration-module--setName--72404";
export var setSummary = "Configuration-module--setSummary--c57c8";
export var setSymbol = "Configuration-module--setSymbol--c4e0b";
export var span = "Configuration-module--span--aec96";
export var standardPercent = "Configuration-module--standardPercent--d1c0d";