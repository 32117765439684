import { useCallback, useMemo, useState } from 'react'
import { clamp } from 'lodash'

import * as scryfall from 'utils/scryfall'

import { copyToClipboard } from 'utils/copyToClipboard'
import { downloadText } from 'utils/downloadText'
import { downloadCSV } from 'utils/downloadCSV'

import { SetWithCards } from './fetchCards'
import { buildList } from './buildList'
import { formatText, formatTextWithSet } from './formatText'
import { formatCSV } from './formatCSV'
import { usePersistentState } from 'src/utils/usePersistentState'
import { notNan } from 'src/utils/typeConversions'

const defaultCounts = {
  common: 4,
  uncommon: 2,
  rare: 1,
  mythic: 1,
}

type ExportFormat = 'name' | 'name-set' | 'csv'

export function useListConfiguration(set: SetWithCards | null) {
  const [counts, setCounts] = useState(defaultCounts)
  const setCountForRarity = useCallback(
    (rarity: string, value: string) => {
      const intValue = parseInt(value)

      setCounts({
        ...counts,
        [rarity]: isNaN(intValue) ? 0 : clamp(intValue, 0, 10),
      })
    },
    [counts],
  )

  const list = useMemo(() => {
    if (set?.cardsByRarity == null) {
      return []
    }
    return buildList(set.cardsByRarity, counts)
  }, [counts, set?.cardsByRarity])

  const [exportFormat, setExportFormat] = usePersistentState<ExportFormat>(
    'set-cube-builder-export-format',
    'name',
  )

  const formatOutput = useCallback(
    (list: scryfall.Card[]) => {
      if (set == null) {
        return ''
      }
      switch (exportFormat) {
        case 'name':
          return formatText(list)
        case 'name-set':
          return formatTextWithSet(list)
        case 'csv':
          return formatCSV(list)
      }
    },
    [exportFormat, set],
  )

  const download = useCallback(() => {
    if (set != null) {
      exportFormat === 'csv'
        ? downloadCSV(formatOutput(list), `${set.code}-set-cube-list`)
        : downloadText(formatOutput(list), `${set.code}-set-cube-list`)
    }
  }, [exportFormat, formatOutput, list, set])

  const copy = useCallback(() => {
    copyToClipboard(formatOutput(list))
  }, [formatOutput, list])

  const stats = useMemo(() => {
    if (list.length === 0) {
      return {
        commonPercent: null,
        uncommonPercent: null,
        rareAndMythicPercent: null,
      }
    }

    return {
      commonPercent: notNan(
        (((set?.cardsByRarity?.common?.length ?? 100) * counts.common) /
          list.length) *
          100,
      ),
      uncommonPercent: notNan(
        (((set?.cardsByRarity?.uncommon?.length ?? 80) * counts.uncommon) /
          list.length) *
          100,
      ),
      rareAndMythicPercent: notNan(
        (((set?.cardsByRarity?.rare?.length ?? 60) * counts.rare +
          (set?.cardsByRarity?.mythic?.length ?? 25) * counts.mythic) /
          list.length) *
          100,
      ),
    }
  }, [counts, list.length, set?.cardsByRarity])

  return {
    set,
    counts,
    setCountForRarity,
    list,
    exportFormat,
    setExportFormat,
    download,
    copy,
    stats,
  }
}

export type ListConfiguration = ReturnType<typeof useListConfiguration>
